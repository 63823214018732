<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="titulos"
            item-text="titulo"
            v-model="selecionados"
            :options.sync="options"
            :loading="loading"
            :single-select="false"
            item-key="id_titulo"
            show-select
            :footer-props="{
                itemsPerPageOptions: [300, 600, 900],
                itemsPerPageText: 'Itens por página:'
            }"
            :server-items-length="totalTitulos"
            loading-text="Pesquisando títulos..."
            no-data-text="Preencha os filtros e clique em filtrar..."
            no-results-text="Nenhum título encontrado..."
            class="elevation-1 pa-3"
        >
            <!-- <template v-slot:item="{ item }">
                <v-checkbox :disabled="selecionados.length >= 100" v-model="item.selected"></v-checkbox>
            </template> -->
            <template v-slot:[`item.id_titulo`]="{ item }">
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.id_titulo }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.id_titulo
                }}</span>
            </template>
            <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                <!---<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.ds_numero_titulo }}</span>--->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.ds_nosso_numero }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.ds_nosso_numero
                }}</span>
            </template>
            <template v-slot:[`item.dt_cadastro`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_cadastro }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.dt_cadastro }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.dt_cadastro
                }}</span>
            </template>
            <template v-slot:[`item.dt_vencimento`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ formatDataVencimento(item) }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ formatDataVencimento(item) }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    formatDataVencimento(item)
                }}</span>
            </template>
            <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ formatDocumento(item) }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ formatDocumento(item) }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    formatDocumento(item)
                }}</span>
            </template>
            <template v-slot:[`item.nm_devedor`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.nm_devedor }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.nm_devedor }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.nm_devedor
                }}</span>
            </template>
            <template v-slot:[`item.vl_saldo_protesto`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.vl_saldo_protesto }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.vl_saldo_protesto }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.vl_saldo_protesto
                }}</span>
            </template>
            <template v-slot:[`item.cd_status`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ getDescSituacao(item) }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ getDescSituacao(item) }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    getDescSituacao(item)
                }}</span>
            </template>
            <template v-slot:[`item.dt_atualizacao`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_atualizacao }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.dt_atualizacao }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.dt_atualizacao
                }}</span>
            </template>
            <template v-slot:[`item.cd_uf_devedor`]="{ item }">
                <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.cd_uf_devedor }}</span>-->
                <span
                    v-if="item.ds_revisao != ''"
                    title="Este título precisa ser revisado. "
                    :style="{ color: 'red' }"
                    >{{ item.cd_uf_devedor }}</span
                >
                <span v-else :style="{ color: 'black' }">{{
                    item.cd_uf_devedor
                }}</span>
            </template>
            <template v-slot:[`item.ds_agrupador`]="{ item }">
                <span :style="{ color: 'black' }">{{
                    getDescritivoAgrupador($store, item.ds_agrupador)
                }}</span>
            </template>
            <template v-slot:[`item.oferta`]="{ item }">
                <v-icon
                    :style="{ color: item.oferta > 0 ? 'green' : 'red' }"
                >
                    {{ getBolean(item.oferta) }}</v-icon
                >
            </template>
        </v-data-table>
        <Snackbar
            v-if="feedbackSnackbar"
            :mostrarSnackbar="feedbackSnackbar"
            :corSnackbar="feedbackSnackbarColor"
            :mensagemSnackbar="mensagemfeedbackSnackbar"
            @fecharSnackbar="feedbackSnackbar = false"
        />
    </div>
</template>
<script>
import * as Formatter from '@/helpers/Formatter';
import {
    getDescritivoAgrupador
} from '@/helpers/Utilitarios';
export default {
    name: 'TableWithSelections',
    props: {
        title: {
            type: String,
            required: false
        },
        titulos: {
            type: Array,
            required: true
        },
        totalTitulos: {
            type: Number,
            required: true
        }, 
        loading: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return{
            selecionados: [],
            situacoesTitulo: this.$store.getters.situacoesTitulo,
            // loading: false,
            feedbackSnackbarTimeout: 5000,
            feedbackSnackbarColor: 'red',
            feedbackSnackbar: false,
            mensagemfeedbackSnackbar:
                'Você não pode selecionar mais de 900 títulos',
            options: {
                page: 1,
                itemsPerPage: 300
            },
            headers: [
                {
                    text: 'Código do título',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'Data de envio',
                    align: 'left',
                    sortable: true,
                    value: 'dt_cadastro'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                { text: 'Nome', sortable: true, value: 'nm_devedor' },
                {
                    text: 'Valor R$',
                    sortable: true,
                    value: 'vl_saldo_protesto'
                },
                { text: 'Status', sortable: true, value: 'cd_status' },
                {
                    text: 'Data de atualização',
                    align: 'left',
                    sortable: true,
                    value: 'dt_atualizacao'
                },
                { text: 'UF', sortable: true, value: 'cd_uf_devedor' },
                // { text: 'Renegociação', sortable: true, value: 'oferta' },
                { text: 'Agrupador', sortable: true, value: 'ds_agrupador' },
                { text: ' ', sortable: true, value: 'action' }
            ],
        }
    },
    watch: {
        selecionados(val, oldVal) {
            if (val.length > 900) {
                this.feedbackSnackbar = true;
                this.$nextTick(() => {
                    this.selecionados = oldVal;
                });
            }
            this.$emit('selecionados', val);
        },
    },
    methods:{
        getDescritivoAgrupador,
        resetSelecionados() {
            this.selecionados = [];
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        }
    }

};
</script>